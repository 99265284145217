import { lazy } from 'react';
const Index = lazy(() => import('../pages/Index'));
const Services = lazy(() => import('../pages/Services'));
const AboutUs = lazy(() => import('../pages/AboutUs'));
const ContactUs = lazy(() => import('../pages/ContactUs'));
const TermsCondition = lazy(() => import('../pages/TermsConditions'));
const PrivacyPolicy = lazy(() => import('../pages/PrivacyPolicy'));
const FAQs = lazy(() => import('../pages/Faq'));
const Error404 = lazy(() => import('../pages/404'));
// custom page
const SendPackage = lazy(() => import('../pages/SendPackage'));
// To replace
const InstantQuote = lazy(() => import('../pages/InstantQuote'));
// sb pages
const Login = lazy(() => import('../pages/Auth/Login'));
const Register = lazy(() => import('../pages/Auth/Register'));
// const AccountVerfication = lazy(() => import('../pages/Auth/AccountVerfication'));
const ForgetPassword = lazy(() => import('../pages/Auth/ForgetPassword'));
const ResetPassword = lazy(() => import('../pages/Auth/ResetPassword'));

// services details
const ExpressDelivery = lazy(() => import('../pages/ServicesDetails/InStorePickup'));
const ForgotSomething = lazy(() => import('../pages/ServicesDetails/ForgotSomething'));
const MarketPlacePickup = lazy(() => import('../pages/ServicesDetails/MarketPlacePickup'));
const PickupAndDrop = lazy(() => import('../pages/ServicesDetails/PickupAndDrop'));
const EcommerceReturn = lazy(() => import('../pages/ServicesDetails/EcommerceReturn'));
const ErrandsRun = lazy(() => import('../pages/ServicesDetails/ErrandsRun'));

const BusinessSolution = lazy(() => import('../pages/ServicesDetails/BusinessSolution'));

const Career = lazy(() => import('../pages/Career'));

// protect
import ProtectedRoute from '../pages/Auth/ProtectedRoute';

// devpage
const DevTools = lazy(() => import('../pages/Dev/devTools'));

const routes = [
    {
        path: '/',
        element: <Index />,
    },
    {
        path: '/login',
        element: <Login />,
    },
    {
        path: '/signup',
        element: <Register />,
    },
    // {
    //     path: '/verify-account/:token/:email',
    //     element: <AccountVerfication />,
    // },
    // {
    //     path: '/verify-account',
    //     element: <AccountVerfication />,
    // },
    {
        path: '/forget-password',
        element: <ForgetPassword />,
    },
    {
        path: '/reset-password',
        element: <ResetPassword />,
    },
    {
        path: '/services',
        element: <Services />,
    },
    {
        path: '/about-us',
        element: <AboutUs />,
    },
    {
        path: '/contact-us',
        element: <ContactUs />,
    },

    {
        path: '/terms-conditions',
        element: <TermsCondition />,
    },
    {
        path: '/privacy-policy',
        element: <PrivacyPolicy />,
    },
    {
        path: '/faq',
        element: <FAQs />,
    },
    // {
    //     path: '/send-package',
    //     element: <SendPackage />,
    //     protected: true,
    // },
    {
        path: '/in-store-pickup-services',
        element: <ExpressDelivery />,
    },
    {
        path: '/forgot-something-services',
        element: <ForgotSomething />,
    },
    {
        path: '/marketplace-pickup-dropoff-service',
        element: <MarketPlacePickup />,
    },
    {
        path: '/instant-picukp-drop-package-service',
        element: <PickupAndDrop />,
    },
    {
        path: '/business-picukp-drop-service',
        element: <BusinessSolution />,
    },
    {
        path: '/errarnds-run-picukp-drop-service',
        element: <ErrandsRun />,
    },
    {
        path: '/ecommerce-return-picukp-drop-service',
        element: <EcommerceReturn />,
        protected: false,
    },
    {
        path: '/career',
        element: <Career />,
    },
    {
        path: '/instant-quote',
        element: <InstantQuote />,
    },
    {
        path: '/dev-icons',
        element: <DevTools />,
    },
    {
        path: '*',
        element: <Error404 />,
    },
];

export { routes };
