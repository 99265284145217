// contactQueries.js
import { gql } from '@apollo/client';

export const LOGIN_NOW = gql`
    mutation login($email: String!, $password: String!) {
        login(email: $email, password: $password) {
            token
            refreshToken
            code
            message
            success
        }
    }
`;

export const SIGNUP_NOW = gql`
    mutation Signup($sigupInput: SignupInput!) {
        signup(sigupInput: $sigupInput) {
            code
            message
        }
    }
`;

export const VERIFY_TOKEN = gql`
    mutation RefreshToken($token: String!) {
        verifyToken(token: $token) {
            valid
            code
            message
        }
    }
`;

export const REFRESH_TOKEN = gql`
    mutation RefreshToken($refreshToken: String!) {
        refreshToken(refreshToken: $refreshToken) {
            code
            message
            token
            refreshToken
        }
    }
`;

export const REQUEST_NEW_VERIFICATION_TOKEN = gql`
    mutation RequestNewConfirmationToken($input: newTokenInput) {
        requestNewConfirmationToken(input: $input) {
            code
            message
            success
        }
    }
`;

export const VERIFY_ACCOUNT_CONFIRM_EMAIL = gql`
    mutation ConfirmEmail($input: confirmEmailInput) {
        confirmEmail(input: $input) {
            code
            message
            success
        }
    }
`;

export const REQUEST_RESET_PASSWORD = gql`
    mutation RequestForgetPasswordToken($input: newTokenInput) {
        requestForgetPasswordToken(input: $input) {
            code
            message
            success
        }
    }
`;

export const RESET_PASSWORD = gql`
    mutation ResetPassword($input: resetPasswordInput) {
        resetPassword(input: $input) {
            code
            message
            success
        }
    }
`;

export const LOGIN_WITH_GOOGLE_NOW = gql`
    mutation LoginWithGoogle($input: GoogleLoginInput) {
        loginWithGoogle(input: $input) {
            token
            refreshToken
            code
            message
            success
        }
    }
`;

export const GET_USERS = gql`
    query GetUsers($page: Int!, $limit: Int!) {
        users(page: $page, limit: $limit) {
            id
            name
            email
            status
            roles
            referral
            referralSource
            # ... other fields ...
        }
    }
`;
