// userQueries.js
import { gql } from '@apollo/client';

export const GETUSERS_NOW = gql`
    query GetAllUsers {
        getAllUsers {
            encId
            name
            surname
            email
            contact
            referral
            referralSource
            Role {
                name
                description
            }
        }
    }
`;

export const GETUSERS_BY_ENCID = gql`
    query GetUser($encId: String!) {
        getUser(encId: $encId) {
            encId
            name
            surname
            email
            referral
            referralSource
            Role {
                name
                description
            }
            code
            message
            success
        }
    }
`;

export const MUTATE_ASSIGN_ROLE = gql`
    mutation AssignRoleToUser($input: RoleInputById) {
        assignRoleToUser(input: $input) {
            code
            message
            success
        }
    }
`;

export const MY_PROFILE = gql`
    query MyProfile {
        myProfile {
            encId
            name
            surname
            email
            referral
            Role {
                name
                description
            }
            code
            message
            success
            profilePic
            isSocialLogin
            emailVerified
        }
    }
`;

export const GET_USER_DETAILS = gql`
    query GetUserDetails($userId: ID!) {
        user(id: $userId) {
            id
            name
            email
            status
            Role {
                description
            }
            referral
            referralSource
            # ... other fields ...
        }
    }
`;
